 <div class="image-carousel-container">
  <div class="image-carousel-buttons" *ngIf="mediaItemsPhoto.length > 0 && mediaItemsVideo.length > 0">
    <button 
        [ngClass]="{'active': selectedFilter === 'all'}" 
        (click)="filterEvents('all')">{{ "page.detail.carousel.filters.all" | translate}}</button>
    <button 
        [ngClass]="{'active': selectedFilter === 'video'}" 
        (click)="filterEvents('video')">{{ "page.detail.carousel.filters.videos" | translate}}</button>
    <button 
        [ngClass]="{'active': selectedFilter === 'photo'}" 
        (click)="filterEvents('photo')">{{ "page.detail.carousel.filters.photos" | translate}}</button>
  </div>
  <div class="image-carousel">
    <div class="image-wrapper" [title]="mediaItem.title || 'StreApp thumbnail'" *ngFor="let mediaItem of filteredMediaItems;" (click)="clickedMediaItem(mediaItem)" [class.active]="mediaItem === selectedMediaItem">
      <img class="media-image"  [src]="mediaItem.thumbnail" />
      <span *ngIf="mediaItem.mediaType == MediaType.Video">
        <mat-icon>play_circle_outline</mat-icon>
      </span>
    </div>
  </div>
</div> 
