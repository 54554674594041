import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserProfile } from 'src/app/models/profile.model';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'about-me-field',
  templateUrl: './about-me-field.component.html',
  styleUrl: './about-me-field.component.scss'
})
export class AboutMeFieldComponent implements OnChanges, OnInit {
  @Input() description : string = '';
  @Input() userInfo : UserProfile;

  editMode: boolean = false;
  loading : boolean = true;

  profileFormGroup: UntypedFormGroup;

  reporter: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private readonly materialSnackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    // Initialize the form group with default values
    this.profileFormGroup = this._formBuilder.group({
      aboutme: [
        { value: this.description, disabled: true },
        [Validators.required, Validators.maxLength(500)],
      ],
    });

    // Set reporter status if userInfo is available
    this.reporter = this.userInfo?.isReporter;

    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.description && this.description && this.profileFormGroup) {
      this.profileFormGroup.patchValue({
        aboutme: this.description,
      });
    }

    if (changes.userInfo && this.userInfo) {
      this.reporter = this.userInfo.isReporter;
    }
  }

  cancel() : void {
    //Disable fields
    this.profileFormGroup.get('aboutme').disable();

    //Reset to old value
    this.profileFormGroup.reset({
      aboutme: this.description
    });

    this.editMode = false;
  }

  edit() : void {
    //Set fields to be enabled
    this.profileFormGroup.get('aboutme').enable();

    this.editMode = true;
  }

  async save(): Promise<void> {
    if(this.profileFormGroup.dirty && this.profileFormGroup.valid && this.reporter) {
      //Have to send birthday with it for some reason atm, TODO: change minimum requirements for updating profile
      var aboutMe = { 
        profile: this.profileFormGroup.get('aboutme').value,
        birthday: this.userInfo.birthDay //tmp, need to change back-end
      };

      await this.authService.updateProfile(JSON.stringify(aboutMe));

      this.profileFormGroup.get('aboutme').disable();

      this.description = this.profileFormGroup.get('aboutme').value;

      this.materialSnackbar.open(
        `Uw wijzingen zijn opgeslagen!`,
        'Ok',
        {
          duration: 5000,
          panelClass: "snackbar-successful",
        }
      );

      this.editMode = false;

      this.authService.loginSilent().then(() => {
        this.editMode = false;
      });
    } else {
      this.cancel();
    }
  }

  getFabStyle(): string {
    if (this.profileFormGroup.dirty && this.profileFormGroup.valid) {
      return 'active';
    } else {
      return '';
    }
  }
}
