<div class="desktop-width-limiter newevent-page page-padding">
  <div class="create-event-page">
  <div class="create-event-container">
    <!-- Stepper Container -->
    <div class="stepper-container">
      <mat-stepper (selectionChange)="onStepChange($event)"  orientation="vertical" #stepper>
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>

        <!-- Step 1: Media Upload -->
        <mat-step [state]="getStepState(0)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step_media_upload.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(0)">S</span>
            </div>
          </ng-template>
        </mat-step>

        <!-- Step 2: Location Selection -->
        <mat-step [state]="getStepState(1)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step3.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(1)">T</span>
            </div>
          </ng-template>
        </mat-step>

        <!-- Step 3: Description & Title -->
        <mat-step [state]="getStepState(2)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step4.data_header" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(2)">R</span>
            </div>
          </ng-template>
        </mat-step>   

        <!-- Step 4: Event Type -->
        <mat-step [state]="getStepState(3)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step1.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(3)">E</span>
            </div>
          </ng-template>
        </mat-step>
        
        <!-- Step 5: Details -->
        <mat-step [state]="getStepState(4)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step2.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(4)">A</span>
            </div>
          </ng-template>
        </mat-step>

        <!-- Step 6: Price Selection -->

        <!-- Step if mediaitems are uploaded -->
        <mat-step [state]="getStepState(5)" *ngIf="preUploadedMediaItems.length > 0" >
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step_price_select.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(5)">P</span>
            </div>
          </ng-template>
        </mat-step>

        <!-- Step if no mediaitems are uploaded -->
        <mat-step [state]="getStepState(5)" *ngIf="preUploadedMediaItems.length === 0" >
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step_price_select.label" | translate
              }}</span>
              <span class="step-box-disabled">P</span>
            </div>
          </ng-template>
        </mat-step>

        <!-- Step 7: Confirmation -->
        <mat-step [state]="getStepState(6)">
          <ng-template matStepLabel>
            <div class="step-content">
              <span class="step-text">{{
                "new_event.step5.label" | translate
              }}</span>
              <span class="step-box" [ngClass]="getStepBoxClass(6)">P</span>
            </div>
          </ng-template>
        </mat-step>
      </mat-stepper>
    </div> 

    <!-- Content Container -->
    <div class="content-container">

      <!-- Step 1: Media Upload -->
      <div *ngIf="this.currentStepIndex === 0">
        <h2>{{ "new_event.step_media_upload.streapp_title" | translate }}</h2>
        <div class="upload-field-with-description">
          <!-- File drop veld -->
          <div class="upload-field">
            <ngx-file-drop
              (onFileDrop)="dropped($event, 'none')"
              [multiple]="true"
              [accept]="'video/*,image/*'"
              class="size-m file-drop"
              [disabled]="preUploadedMediaItems.length >= 10"
            >
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="file-selector" (click)="openFileSelector()">
                  <span class="upload-field-text">{{
                    "new_event.step4.dropzone_label" | translate
                  }}</span>
                  <mat-icon>cloud_upload</mat-icon>
                </div>
              </ng-template>
            </ngx-file-drop>
            <!-- File counter with maximum of 10 files -->
            <div class="totalFilesUploaded">
              {{ preUploadedMediaItems.length }} / 10
            </div>
          </div>
          <div class="uploadDescriptionField">
              {{
                "new_event.step_media_upload.upload_description" | translate
              }}
          </div>
        </div>

        <!-- Image file preview -->
        <div class="preview-holder">
          <div
            class="preview"
            *ngFor="let item of numSequence(10); let i = index">
            <ng-container *ngIf="preUploadedMediaItems[i]">
              <img
                class="preview-image-holder"
                [src]="preUploadedMediaItems[i].previewFile"
                onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                alt="uploaded-image"
              />
              <div class="darkenImage"></div>
              <span
                class="remove-item"
                typeof="button"
                (click)="removeFile(i, 'none')"
                [title]="'general.remove' | translate | titlecase"
              >
              <mat-icon>delete</mat-icon>
              </span>
            </ng-container>
            <ng-container *ngIf="!preUploadedMediaItems[i]">
              <img
                class="preview-image-holder-empty"
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              />
            </ng-container>
          </div>
        </div>

       <!-- Filepreview Holders Shop-only -->
       <ng-container *ngIf="isReporter">
        <h2 class="shop-only-content-title">
          {{ "new_event.step_media_upload.shop_only_title" | translate }}
        </h2>
        <div class="upload-field-with-description">
          <!-- File drop veld -->
          <div class="upload-field">
            <div
              class="image-description"
              *ngIf="!!linkedEvent"
              [innerHTML]="'new_event.step4.description_comment' | translate"
            ></div>
            <ngx-file-drop
              (onFileDrop)="dropped($event, 'shop')"
              [multiple]="true"
              [accept]="'video/*,image/*'"
              class="size-m file-drop"
              [disabled]="preUploadedShopMediaItems.length >= 10"
            >
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="file-selector" (click)="openFileSelector()">
                  <span class="upload-field-text">{{
                    "new_event.step4.dropzone_label" | translate
                  }}</span>
                  <mat-icon>cloud_upload</mat-icon>
                </div>
              </ng-template>
            </ngx-file-drop>
            <!-- File counter with maximum of 10 files -->
            <div class="totalFilesUploaded">
              {{ preUploadedShopMediaItems.length }} / 10
            </div>
          </div>
          <div class="uploadDescriptionField">
            {{
              "new_event.step_media_upload.upload_description_shop" | translate
            }}
          </div>
        </div>
        <div class="preview-content">
          <div class="preview-holder">
            <div
              class="preview"
              *ngFor="let item of numSequence(10); let i = index"
            >
              <ng-container *ngIf="preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder"
                  [src]="preUploadedShopMediaItems[i].previewFile"
                  onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                  alt="uploaded-image"
                />
                <div class="darkenImage"></div>
                <span
                  class="remove-item"
                  typeof="button"
                  (click)="removeFile(i, 'shop')"
                  [title]="'general.remove' | translate | titlecase"
                >
                  <mat-icon>delete</mat-icon>
                </span>
              </ng-container>
              <ng-container *ngIf="!preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder-empty"
                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                />
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>   
      </div>  
  
      <!-- Step 2: Location Selection -->
      <div *ngIf="this.currentStepIndex === 1">
        <h2>{{ "new_event.step3.label" | translate }}</h2>
        <div class="location-selector">
          <location-selector-azure
            #locationSelector
            (mapClicked)="setLocation($event)"
          ></location-selector-azure>
        </div>
      </div>
     
          <!-- Step 3: Title & description  -->
          <div *ngIf="this.currentStepIndex === 2">
            <form [formGroup]="streappFormGroup">
              <ng-template matStepLabel>{{
                "new_event.step4.label" | translate
              }}</ng-template>
              <div>
                <h2>{{ "new_event.step4.data_header" | translate }}</h2>
                <ng-container *ngIf="!linkedEvent">
                  <!-- Titel veld -->
                  <mat-form-field appearance="outline" class="size-s">
                    <mat-label>{{
                      "new_event.step4.titel_label" | translate
                    }}</mat-label>
                    <input
                      id="title"
                      #titel
                      matInput
                      formControlName="titel"
                      [placeholder]="'new_event.step4.titel_placeholder' | translate"
                      required
                    />
                    <mat-hint
                      >{{ titel.value?.length || 0 }} / 150</mat-hint
                    >
                    <mat-error>{{
                      "new_event.step4.titel_validation" | translate
                    }}</mat-error>
                  </mat-form-field>
                </ng-container>
      
                <!-- Beschrijving veld -->
                <mat-form-field appearance="outline" class="size-m">
                  <mat-label>{{
                    "new_event.step4.description_label" | translate
                  }}</mat-label>
                  <textarea
                    id="description"
                    #description
                    required
                    maxlength="5000"
                    autosize
                    rows="3"
                    minRows="3"
                    maxRows="10"
                    formControlName="beschrijving"
                    matInput
                    [placeholder]="
                      'new_event.step4.description_placeholder' | translate
                    "
                  ></textarea>
                  <mat-hint
                    >{{ description.value?.length || 0 }} / 5000</mat-hint
                  >
                  <mat-error>{{
                    "new_event.step4.description_validation" | translate
                  }}</mat-error>
                </mat-form-field>
      
                <mat-form-field
                  appearance="outline"
                  class="size-s"
                  *ngIf="!linkedEvent"
                >
                  <mat-label>{{
                    "new_event.step4.date_label" | translate
                  }}</mat-label>
                  <input
                    [min]="minDate"
                    [max]="maxDate"
                    type="datetime-local"
                    matInput
                    name="datumTijd"
                    formControlName="datumTijd"
                    required
                  />
                  <mat-error>{{
                    "new_event.step4.date_validation" | translate
                  }}</mat-error>
                </mat-form-field>

              <!-- Tags veld -->
              <mat-form-field
                appearance="outline"
                class="size-s"
                *ngIf="!linkedEvent"
              >
                <mat-label>{{
                  "new_event.step4.tags_label" | translate
                }}</mat-label>
                <input
                  id="tags"
                  #tags
                  matInput
                  formControlName="tags"
                  [placeholder]="'new_event.step4.tags_placeholder' | translate"
                />
                <mat-hint>{{ tags.value?.length || 0 }} / 200</mat-hint>
                <mat-error>{{
                  "new_event.step4.tags_validation" | translate
                }}</mat-error>
              </mat-form-field>
            </div>
          </form>
        </div>

      <!-- Step 4: Event Type -->
      <div *ngIf="this.currentStepIndex === 3">
        <!-- Event type -->
        <div *ngIf="!this.categoryStepCompleted">
          <h2>{{ "new_event.step1.label" | translate }}</h2>
          <div class="input-select-type">
            <ng-container *ngFor="let eventType of availableEventTypes">
              <event-type-selector
                [linkedEvent]="linkedEvent"
                [eventType]="eventType"
                [selected]="eventType === selectedEvent"
                (selectedEventType)="selectEventType($event)"
              ></event-type-selector>
            </ng-container>
          </div>
        </div>
        <!-- Sub-Event Type -->
        <div *ngIf="this.categoryStepCompleted">
          <h2>{{ "new_event.step1.extra_step_label" | translate }}</h2>
          <div class="input-select-type">
            <ng-container *ngFor="let eventType of selectedEvent.children">
              <event-type-selector
                [linkedEvent]="linkedEvent"
                [eventType]="eventType"
                [selected]="eventType === selectedSubEvent"
                (selectedEventType)="selectSubEventType($event)"
              ></event-type-selector>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Step 5: Details -->
      <div *ngIf="this.currentStepIndex === 4"> 
        <form [formGroup]="streappFormGroup">
          <h2>{{ "new_event.step4.heftigEvent_header" | translate }}</h2>
          <mat-form-field appearance="outline" class="size-xs">
            <mat-select
              id="adultContent"
              formControlName="heftigEvent"
              [placeholder]="
                'new_event.step4.heftigEvent_placeholder' | translate
              "
              required
            >
              <mat-option [value]="'ja'"> Ja </mat-option>
              <mat-option [value]="'nee'"> Nee </mat-option>
            </mat-select>
            <mat-error>{{
              "new_event.step4.heftigEvent_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <ng-container *ngIf="showAdditionalQuestions">
            <h2>{{ "new_event.step4.gewonden_header" | translate }}</h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="wounded"
                formControlName="gewonden"
                (selectionChange)="sendWoundedData($event.value)"
                [placeholder]="
                  'new_event.step4.gewonden_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
                <mat-option [value]="'onbekend'"> Weet ik niet </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.gewonden_validation" | translate
              }}</mat-error>
            </mat-form-field>

            <h2>
              {{ "new_event.step4.hulpdiensten_header" | translate }}
              <button
                type="button"
                mat-icon-button
                color="primary"
                [matTooltip]="'new_event.step4.hulpdiensten_help' | translate"
                matTooltipPosition="above"
              >
                <mat-icon>info_outline</mat-icon>
              </button>
            </h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="emergencyServices"
                formControlName="hulpdiensten"
                (selectionChange)="sendEmergencyServicesData($event.value)"
                [placeholder]="
                  'new_event.step4.hulpdiensten_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.hulpdiensten_validation" | translate
              }}</mat-error>
            </mat-form-field>
            <h3
              class="warning-hulpdiensten"
              *ngIf="streappFormGroup.get('hulpdiensten')?.value === 'nee'"
            >
              {{ "new_event.step4.hulpdiensten_warning" | translate }}
            </h3>
          </ng-container>
        </form> 
      </div>

      <!-- Step 6: Price Selection -->
      <div *ngIf="this.currentStepIndex === 5 && this.preUploadedMediaItems.length > 0">
        <h2>{{ "new_event.step_price_select.label" | translate }}</h2>
        <p *ngIf="!isReporter"> {{ "new_event.step_price_select.non_reporter_askingprice_text" | translate }} </p>
        <mat-slide-toggle *ngIf="!isReporter" [(ngModel)]="isAskingPriceEnabled"></mat-slide-toggle>

        <ng-template matStepLabel>{{
          "new_event.step_price_select.label" | translate
        }}</ng-template>
        <!-- All uploaded files with prices/suggested prices -->
        <h2 class="price-title">
          {{ "new_event.step_media_upload.streapp_title" | translate }}
        </h2>
        <div class="price-suggestion-holder" *ngIf="isReporter || isAskingPriceEnabled">
          <div
            class="price-suggestion-item"
            *ngFor="let item of preUploadedMediaItems; let i = index"
            [ngStyle]="{ 'background-image': 'url(' + item.previewFile + ')' }"
            (click)="openPriceSelectionWindow(i, 'none')">
            <button
              class="price-suggestion-button"
              type="button"
              mat-raised-button
              (click)="openPriceSelectionWindow(i, 'none')"
            >
              <span *ngIf="!item.priceChanged"
                >{{
                  "new_event.step_price_select.suggestion_label" | translate
                }}
                {{ item.suggestedPrice }} <b>€</b></span
              >
              <span *ngIf="item.priceChanged"> {{ item.price }} <b>€</b></span>
            </button>
          </div>
          <div class="advice-description">
            {{ "new_event.step_price_select.advice_description" | translate }}
          </div>
        </div>

        <ng-container *ngIf="preUploadedShopMediaItems.length > 0">
          <h2 class="price-title shop-only-content-title">
            {{ "new_event.step_media_upload.shop_only_title" | translate }}
          </h2>
          <div class="price-suggestion-holder">
            <!-- Shop only items -->
            <div
              class="price-suggestion-item"
              *ngFor="let item of preUploadedShopMediaItems; let i = index"
              [ngStyle]="{
                'background-image': 'url(' + item.previewFile + ')'
              }"
              (click)="openPriceSelectionWindow(i, 'shop')"
            >
              <button
                class="price-suggestion-button"
                type="button"
                mat-raised-button
                (click)="openPriceSelectionWindow(i, 'shop')"
              >
                <span *ngIf="!item.priceChanged"
                  >{{
                    "new_event.step_price_select.suggestion_label" | translate
                  }}
                  {{ item.suggestedPrice }} <b>€</b></span
                >
                <span *ngIf="item.priceChanged">
                  {{ item.price }} <b>€</b></span
                >
              </button>
            </div>
            <div class="advice-description">
              {{ "new_event.step_price_select.advice_shop" | translate }}
            </div>
          </div>
        </ng-container>
      </div>
  
      <!-- Step 7: Confirmation -->
      <div *ngIf="this.currentStepIndex === 6">
        <div class="step5">
          <div class="linked-event" *ngIf="!!linkedEvent">
            <span class="linked-label">{{
              "new_event.step5.linked_event_label" | translate
            }}</span>
            <overview-card
              [event]="linkedEvent"
              [navigateToEventOnClick]="false"
            ></overview-card>
          </div>
          <div class="row" *ngIf="!linkedEvent">
            <div class="col-2 label">
              {{ "new_event.step4.titel_label" | translate }}:
            </div>
            <div class="col-10">{{ streappFormGroup.get("titel")?.value }}</div>
          </div>
          <div class="row">
            <div class="col-2 label">
              {{ "new_event.step4.description_label" | translate }}:
            </div>
            <div class="col-10">
              {{ streappFormGroup.get("beschrijving")?.value }}
            </div>
          </div>
          <div class="row" *ngIf="!linkedEvent">
            <div class="col-2 label">
              {{ "new_event.step4.date_label" | translate }}:
            </div>
            <div class="col-10">
              {{ streappFormGroup.get("datumTijd")?.value | dateToDescription }}
            </div>
          </div>
          <div class="row" *ngIf="!linkedEvent">
            <div class="col-2 label">
              {{ "new_event.step4.tags_label" | translate }}:
            </div>
            <div class="col-10">{{ streappFormGroup.get("tags")?.value }}</div>
          </div>
          <div class="row" *ngIf="!linkedEvent">
            <div class="col-2 label">
              {{ "new_event.step3.location_label" | translate }}:
            </div>
            <div class="col-10">
              {{ locationAdress }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 label">
              {{ "new_event.step4.heftigEvent_header" | translate }}:
            </div>
            <div class="col-10">
              {{ streappFormGroup.get("heftigEvent")?.value }}
            </div>
          </div>
          <ng-container *ngIf="showAdditionalQuestions">
            <div class="row">
              <div class="col-2 label">
                {{ "new_event.step4.gewonden_header" | translate }}:
              </div>
              <div class="col-10">
                {{ streappFormGroup.get("gewonden")?.value }}
              </div>
            </div>
            <div class="row">
              <div class="col-2 label">
                {{ "new_event.step4.hulpdiensten_header" | translate }}:
              </div>
              <div class="col-10">
                {{ streappFormGroup.get("hulpdiensten")?.value }}
              </div>
            </div>
          </ng-container>
          <!-- Gekozen content -->
          <div class="row">
            <div class="col-2 label">
              {{ "new_event.step4.selected_file" | translate }}:
            </div>
            <div class="col-12">
              <!-- File preview -->
              <div class="files-selected-summary">
                <div class="summary-preview-holder">
                  <div
                    class="preview"
                    *ngFor="let item of preUploadedMediaItems">
                      <img
                        class="preview-image-holder"
                        [src]="item.previewFile"
                        onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                        alt="uploaded-image"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Shop content -->
          <div
            class="row"
            *ngIf="isReporter && preUploadedShopMediaItems.length > 0"
          >
            <div class="col-2 label">
              {{ "new_event.step4.selected_file_shop_only" | translate }}:
            </div>
            <div class="col-12">
              <div class="files-selected-summary">
                <div class="summary-preview-holder" >
                  <div
                    class="preview"
                    *ngFor="let item of preUploadedShopMediaItems; let i = index">
                    <img
                      class="preview-image-holder"
                      [src]="item.previewFile"
                      onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                      alt="uploaded-image"
                    />
                    <mat-spinner
                      [diameter]="25"
                      [mode]="'determinate'"
                      [value]="item.progress"
                      *ngIf="!!item.progress && !item.finished"
                    >
                    </mat-spinner>
                    <mat-icon class="error" *ngIf="item.hasError">
                      report_problem
                    </mat-icon>
                    <mat-icon *ngIf="item.finished">
                      check_circle_outline
                    </mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="button-actions">
            <span *ngIf="publishing" class="publishing-text">
              <mat-progress-spinner
                class="loading-spinner"
                [diameter]="30"
                mode="indeterminate"
              ></mat-progress-spinner>
              <span
                >{{ "new_event.step5.uploading" | translate }}
                {{ getProgress() }}</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>

</div>

<div class="create-event-buttons">
  <!-- Previous button -->
    <div class="button-wrapper-left">
      <button 
        matRipple
        *ngIf="currentStepIndex !== 0 && !publishing" 
        (click)="previousStep()" 
        class="secondary-button">
        <mat-icon>keyboard_arrow_left</mat-icon>
        {{ "general.previous" | translate | titlecase }}
    </button>
  </div>
  <div class="button-wrapper">
    <!-- Next Button -->
    <button  
      matRipple
      *ngIf="(currentStepIndex !== 6 && currentStepIndex !== 3) || (currentStepIndex === 3 && isStepCompleted(3))"
      [disabled]="!isStepCompleted(currentStepIndex)"
      (click)="nextStep()"
      class="secondary-button">
      {{ "general.next" | translate | titlecase }}
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <!-- Create streapp button -->
    <button
      matRipple
      *ngIf="currentStepIndex === 6 && this.streappFormGroup.valid && !publishing"
      id="submit"
      class="save-button"
      (click)="createEvent()"
      [disabled]="!streappFormGroup.valid || publishing">
      {{ "new_event.step5.button_publish" | translate | titlecase }}
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>

</div>
</div>
