import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateBannerDialogComponent } from '../update-banner-dialog/update-banner-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent implements OnChanges, OnInit {
  @Input() profile : StreApperInfo;
  @Input() userType : number = 0; //0 = Standard User, 1 = StreappReporter, 2 = Prospect

  reporter: boolean;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userService.userInfo$.subscribe((profile) => {
      if (profile != null) {
        this.profile.name = profile.firstName + ' ' + profile.lastName;

        if (this.profile.picture) {
          this.profile.picture = `${
            this.profile.picture
          }?time=${new Date().getTime()}`;
        }
      }
    });

    this.userService.imageUrl$.subscribe((url) => {
      if (url != null) {
        this.profile.picture = url;
      }
    });

    this.userService.bannerImageUrl$.subscribe((url) => {
      if (url != null) {
        this.profile.banner = url;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile && this.profile) {
      this.reporter = this.profile.isReporter;
    }
  }

  getProfilePicture() {
    if (this.profile.picture) {
      if (this.profile.picture.startsWith('blob') || this.profile.picture.startsWith('http')) {
        return this.profile.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.profile.picture
      );
    }
  }

  edit(): void {
    //open banner & profile edit dialog
    this.dialog.open<UpdateBannerDialogComponent>(UpdateBannerDialogComponent, {
      autoFocus: false, // To prevent select state of close button in header
      panelClass: 'medium',
      data: {
        profile: this.profile,
      },
    });
  }
}
