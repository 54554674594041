import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StreAppPaymentDialogData } from './streapp-payment-dialog-data.model';

@Component({
  selector: 'app-streapp-payment-dialog',
  templateUrl: './streapp-payment-dialog.component.html',
  styleUrl: './streapp-payment-dialog.component.scss'
})
export class StreappPaymentDialogComponent {

  constructor(
    private readonly dialogRef: MatDialogRef<StreappPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: StreAppPaymentDialogData) {}
  
    closeWithConfirm(): void {
      this.dialogRef.close(true);
    }
  
    close() : void {
      this.dialogRef.close(false);
    }
}
