import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ReportingService } from '../../../services/reporting.service';
import { AuthService } from '../../../services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-reporting-dialog',
  templateUrl: './reporting-dialog.component.html',
  styleUrls: ['./reporting-dialog.component.scss']
})
export class ReportingDialogComponent implements OnInit {
  reportFormGroup: UntypedFormGroup;
  hasReported: boolean = false;

  reportingEvent: boolean = false;
  reportingUser: boolean = false;
  reportingReaction: boolean = false;

  selectedReason: string = '';
  reasonsEvent: Array<string> = [
    'Gewonden herkenbaar in beeld',
    'Beelden kloppen niet met de categorie',
    'Seksueel getint',
    'De video en/of foto is gekopieerd',
    'Anders, namelijk:'
  ];
  reasonsUser: Array<string> = [
    'Nepprofiel',
    'Plaatst ongewenste reacties',
    'Plaatst gekopieerde content',
    'Plaatst in scene gezette beelden',
    'Anders, namelijk:'
  ];
  reasonsReaction: Array<string> = [
    'Bevat aanstootgevende tekst',
    'Is seksistisch',
    'Is discriminerend van aard',
    'Anders, namelijk:'
  ];
  reasons: Array<string> = [];

  constructor(private readonly formBuilder: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<ReportingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { eventId: string, userId: string },
    private readonly reportService: ReportingService,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.reportFormGroup = this.formBuilder.group({
      message: ['', [Validators.required, Validators.maxLength(500)]],
    });

    if (!!this.data.eventId && !!this.data.userId) {
      this.reportingReaction = true;
      this.reasons = [...this.reasonsReaction];
    } else if (!!this.data.eventId) {
      this.reportingEvent = true;
      this.reasons = [...this.reasonsEvent];
    } else if (!!this.data.userId) {
      this.reportingUser = true;
      this.reasons = [...this.reasonsUser];
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  report(): void {
    let reason: string = this.selectedReason;
    if (this.selectedReason === 'Anders, namelijk:') {
      reason += ' ' + this.reportFormGroup.controls["message"].value;
    }

    if (this.reportingEvent) {
      this.reportService.submitReport(this.data.eventId, reason, this.authService.authorizationHeaderValue).then(() => {
        this.hasReported = true;
      });
    }

    if (this.reportingUser) {
      this.reportService.submitReport(this.data.userId, reason, this.authService.authorizationHeaderValue, true).then(() => {
        this.hasReported = true;
      });
    }

    if (this.reportingReaction) {
      this.reportService.submitReactionReport(this.data.eventId, this.data.userId, reason, this.authService.authorizationHeaderValue).then(() => {
        this.hasReported = true;
      });
    }
  }
}
