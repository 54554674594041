import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { HelpBoxService } from 'src/app/services/help-box.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-promo-detail',
  templateUrl: './promo-detail.component.html',
  styleUrls: ['./promo-detail.component.scss']
})
export class PromoDetailComponent implements OnInit {

  active: string;
  isImage: boolean;
  promo: any;
  loading: boolean = true;
  color: string;
  countdown: string;

  canSignup: boolean = false;
  alreadySignedUp: boolean = false;

  id: any;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService,
    @Inject(PLATFORM_ID) protected _platformId: Object,
    private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this.route.params.subscribe(params => {
      this.streappService.getPromo(params['id']).then(promo => {
        promo.pageDescription = promo.pageDescription.replace(/(?:\r\n|\r|\n)/g, '<br>');
        promo.signupDescription = promo.signupDescription.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.promo = promo;
        this.checkCanSignup(params['id']);
        this.testImage(promo.pageImageUrl).then(() => {
          this.isImage = true;
        }).catch(() => {
          this.isImage = false; 
        }).finally(() => {
          this.getCountDown();
          this.id = setInterval(() => {this.getCountDown()}, 1000);
          this.loading = false;
        });
      });  
    });
    this.route.url.subscribe(url => {
      this.active = url[2].path;
    });

  }

  checkCanSignup(id: string) {
    if (this.authService.isAuthenticated()) {
      let amountOfStreApps: number;
      this.streappService.getStreapperInfo(this.authService.email).then(info => {
        amountOfStreApps = info.amountOfStreApps;
        this.canSignup = amountOfStreApps > 0;

        this.streappService.checkIfAlreadySignedUp(id, this.authService.authorizationHeaderValue).then(result => {
          if (result) {
            this.alreadySignedUp = true;
          }
        })
      });
    }
  }

  ngOnDestroy() {
    if (this.id) {
      clearInterval(this.id);
    }
  }

  testImage(url) {
    return new Promise(function (resolve, reject) {
        var timeout = 5000;
        var timer, img = new Image();
        img.onerror = img.onabort = function () {
            clearTimeout(timer);
            reject("error");
        };
        img.onload = function () {
            clearTimeout(timer);
            resolve("success");
        };
        timer = setTimeout(function () {
            // reset .src to invalid URL so it stops previous
            // loading, but doesn't trigger new load
            img.src = "//!!!!/test.jpg";
            reject("timeout");
        }, timeout);
        img.src = url;
    });
  }

  getCountDown() {
    let now = new Date();
    let endDate = new Date(this.promo.publishEnd);

    if (now > endDate) {
      this.translate.get('promo.ended').subscribe(text => {
        return text;
      })    
    }

    var diff = Math.abs(endDate.getTime() - now.getTime());
    var diffDays = Math.floor(diff / (1000 * 3600 * 24));
    var diffHours = Math.floor(diff / (1000 * 3600)) - diffDays * 24;
    var diffMinutes = Math.floor((Math.ceil(diff / (1000)) - (diffDays * 24 * 3600) - (diffHours * 3600)) / 60);
    if (diffHours < 0) {
      diffHours = 23 + diffHours;
    }
    var diffMinutes = endDate.getMinutes() - now.getMinutes();
    if (diffMinutes < 0) {
      diffMinutes = 60 + diffMinutes;
    }

    if (diffDays > 0 || diffHours > 8) {
      this.color = 'green';
    } else if (diffHours > 0)
    {
      this.color = 'orange';
    }
    else {
      this.color = 'red';
    }

    this.countdown =  diffDays + ' dg : ' + diffHours + ' uur : ' + diffMinutes + ' min';
  }

  openUrl(url: string) 
  {
    window.open(url, '_blank');
  }

  openSignupDialog() {
    if (this.canSignup) {
      this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(SimpleTextDialogComponent, {
        data: {
          title: 'promo.signup-page.popup_title',
          text: 'promo.signup-page.popup_text',
          showButton: true,
          showCancelButton: true,
          buttonCancelText: 'promo.signup-page.popup_cancel',
          buttonText: 'promo.signup-page.popup_button'
        }
      }).beforeClosed().subscribe((data: boolean) => {
        if (data) {
          this.streappService.signupPromo(this.promo.id, this.authService.authorizationHeaderValue).then(() => {
            this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(SimpleTextDialogComponent, {
              data: {
                title: 'promo.signup-page.popup-confirm_title',
                text: 'promo.signup-page.popup-confirm_text',
                showButton: false,
                showCancelButton: false
              }
            });
          });

          this.alreadySignedUp = true;
        }
      });
    } else {
      this.translate.get('promo.loginRequired').subscribe(text => {
        this.helpBox.openBox(text);
      })    
    }
  }

  openSignoffDialog() {
    this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(SimpleTextDialogComponent, {
      data: {
        title: 'promo.signup-page.popup-cancel_title',
        text: 'promo.signup-page.popup-cancel_text',
        showButton: true,
        showCancelButton: true,
        buttonCancelText: 'promo.signup-page.popup-cancel_cancel',
        buttonText: 'promo.signup-page.popup-cancel_button'
      }
    }).beforeClosed().subscribe((data: boolean) => {
      if (data) {
        this.streappService.cancelSignupPromo(this.promo.id, this.authService.authorizationHeaderValue).then(() => {
          this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(SimpleTextDialogComponent, {
            data: {
              title: '',
              text: 'promo.signup-page.popup-cancel-confirm_text'
            }
          });
          this.alreadySignedUp = false;
        });
      }
    });
  }
}
