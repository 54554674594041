import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleTextDialogComponent } from '../simple-text-dialog/simple-text-dialog.component';
import { SimpleTextDialogData } from '../simple-text-dialog/models/simple-text-dialog-data.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'delete-streapp-button',
  templateUrl: './delete-streapp-button.component.html',
  styleUrl: './delete-streapp-button.component.scss'
})
export class DeleteStreappButtonComponent {
  @Input() eventId : string;
  @Input() eventTitle: string;
  @Input() buttonTitle: string = 'Delete StreApp';

  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly streappEventService: StreappEventsService,
    private readonly materialSnackbar: MatSnackBar,
    private readonly router: Router
  ) {}

  openDeleteDialog() : void {
    this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(
      SimpleTextDialogComponent,
      {
        data: {
          title: 'page.detail.dialog.warning_title',
          text: 'page.detail.dialog.warning_description',
          showButton: true,
          showCancelButton: true,
          buttonCancelText: 'Annuleer',
          buttonText: 'Verwijder',
          dialogType: 'warning'
        },
        autoFocus: false,
        disableClose: true,
      }
    )
    .afterClosed()
    .subscribe((res: boolean) => {
      if (res) {
        this.deleteStreApp();
      }
    })   
  }

  async deleteStreApp(): Promise<void>{
    try {
      const result = await this.streappEventService.deleteEvent(
        this.eventId,
        this.eventTitle,
        this.authService.authorizationHeaderValue
      );
  
      if (result) {
        this.materialSnackbar.open(
          'StreApp succesvol verwijderd.',
          'Ok',
          { 
            duration: 5000,
            panelClass: 'snackbar-successful',
          }
        );
        
        this.router.navigate(['/']);

      } else {
        throw new Error('Een onverwachte error heeft plaatsgevonden tijdens het verwijderen van jouw StreApp.');
      }
    } catch (error) {
      console.error('Error deleting StreApp:', error);
      this.materialSnackbar.open(
        `Fout bij het verwijderen van jouw StreApp. Probeer het nogmaals.`,
        'Ok',
        { 
          duration: 5000,
          panelClass: 'snackbar-fail',
        }
      );
    }
  }
}
