import {
  Component,
  ViewChild,
  Input,
  Inject,
  PLATFORM_ID,
  ElementRef,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SimpleTextDialogComponent } from '../../../components/simple-text-dialog/simple-text-dialog.component';
import { SimpleTextDialogData } from '../../../components/simple-text-dialog/models/simple-text-dialog-data.model';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('video') videoPlayer;
  @ViewChild('rawVideoPlayer') rawVideoPlayer: ElementRef;
  @Input() src: string;
  @Input() poster: string;
  @Input() autoplay: boolean;
  @Input() width: string;
  @Input() height: string;
  @Input() isHighImpactEvent: boolean;
  @Input() rawInput: boolean = false;
  @Input() videoId: string = 'vid1';

  private isWarningShown: boolean = false;
  private myPlayer: amp.Player;
  private ngComponent: VideoPlayerComponent; // Added this to avoid IDE errors

  loading: boolean = true;

  constructor(
    private readonly dialog: MatDialog,
    @Inject(PLATFORM_ID) protected _platformId: Object
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this._platformId)) {
      return;
    }

    if (this.rawInput) {
      this.loading = false;
      return;
    }

    if(!this.src){
      console.log("No source found for video");
      return
    }

    // Dynamically load the amp player control
    this.myPlayer = amp(this.videoPlayer.nativeElement, {
      /* Options */
      controls: true,
      autoplay: this.autoplay,
      logo: { enabled: false },
      poster: this.poster,
    });

    this.myPlayer.width(this.width);
    this.myPlayer.height(this.height);
    this.myPlayer.src({
      src: this.src,
      type: 'application/vnd.ms-sstr+xml',
    });

    this.myPlayer.ngComponent = this;

    this.myPlayer.addEventListener('play', this.video_started);
    this.loading = false;
  }

  onRawVideoStarted(): void {
    if (this.isHighImpactEvent && !this.isWarningShown) {
      this.rawVideoPlayer.nativeElement.pause();
      this.dialog
        .open<SimpleTextDialogComponent, SimpleTextDialogData>(
          SimpleTextDialogComponent,
          {
            data: {
              title: 'page.highImpactModal.title',
              text: 'page.highImpactModal.text',
              showButton: true,
              buttonText: 'page.highImpactModal.buttontext',
            },
          }
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.isWarningShown = true;
            this.rawVideoPlayer.nativeElement.play();
          }
        });
    }
  }

  video_started(): void {
    // This in this context is the myPlayer
    if (
      this.ngComponent.isHighImpactEvent &&
      !this.ngComponent.isWarningShown
    ) {
      this.ngComponent.myPlayer.pause();

      this.ngComponent.dialog
        .open<SimpleTextDialogComponent, SimpleTextDialogData>(
          SimpleTextDialogComponent,
          {
            data: {
              title: 'page.highImpactModal.title',
              text: 'page.highImpactModal.text',
              showButton: true,
              buttonText: 'page.highImpactModal.buttontext',
            },
          }
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.ngComponent.isWarningShown = true;
            this.ngComponent.myPlayer.play();
          }
        });
    }
  }
}
