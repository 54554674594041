import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { MediaItem, MediaType } from '../../models/mediaitem.model';

@Component({
  selector: 'image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {
  @Input() mediaItemArray: Array<MediaItem>;
  @Input() selectedMediaItem: MediaItem;

  @Output() mediaItemClicked: EventEmitter<MediaItem> = new EventEmitter();

  MediaType = MediaType;
  mediaItemsVideo: MediaItem[] = [];
  mediaItemsPhoto: MediaItem[] = [];
  filteredMediaItems: MediaItem[] = [];
  selectedFilter: string = 'all';

  constructor() { }

  clickedMediaItem(image: MediaItem): void {
    this.mediaItemClicked.emit(image);
  }

  ngOnInit(): void {
    this.mediaItemArray.forEach(mediaItem => {
      if (mediaItem.mediaType == MediaType.Video) {
        this.mediaItemsVideo.push(mediaItem);
      }
      if (mediaItem.mediaType == MediaType.Image) {
        this.mediaItemsPhoto.push(mediaItem);
      }
    });
    this.filteredMediaItems = this.mediaItemArray;
  }

  filterEvents(filter: string): void {
    this.selectedFilter = filter;
    if (filter === 'all') {
      this.filteredMediaItems = this.mediaItemArray;
    } else if (filter === 'video') {
      this.filteredMediaItems = this.mediaItemsVideo;
    } else if (filter === 'photo') {
      this.filteredMediaItems = this.mediaItemsPhoto;
    }
  }
}
