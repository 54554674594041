import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";

@Pipe({
  name: 'dateToDescriptionV2'
})
export class DateToDescriptionV2Pipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
          return '';
        }
    
        let d = new Date(value);
        if (isNaN(d.getTime())) {
          return '';
        }
    
        // Format the date as "14 juli 2024"
        const formattedDate = formatDate(d, 'd MMMM yyyy', 'nl-NL');
    
        // Format the time as "15:34 uur"
        const formattedTime = formatDate(d, 'HH:mm', 'nl-NL');
    
        return `${formattedDate} • ${formattedTime}`;
    }
}