import { Component, EventEmitter, Output, Input } from '@angular/core';
import { EventType } from '../_models/eventtype.model';
import { StreappEvent } from 'src/app/models/streappevent.model';

@Component({
  selector: 'event-type-selector',
  templateUrl: './event-type-selector.component.html',
  styleUrls: ['./event-type-selector.component.scss']
})
export class EventTypeSelectorComponent {
  @Input() eventType: EventType;
  @Input() selected: boolean;
  @Input() linkedEvent: StreappEvent;
  @Output() selectedEventType: EventEmitter<string> = new EventEmitter();

  constructor() { }

  selectEvent(event: MouseEvent): void {
    // Prevent changing categories when having a linked event
    if(!this.linkedEvent){
      event.stopPropagation(); // When clicking on the subitem - make sure not to trigger the click on the main div as well
      this.selectedEventType.emit(this.eventType.title);
    }
  }
}
