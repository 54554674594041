import { Component, Input } from '@angular/core';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { StripeService } from 'src/app/services/stripe.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserProfile } from 'src/app/models/profile.model';
import { MatDialog } from '@angular/material/dialog';
import { SimpleTextDialogComponent } from '../simple-text-dialog/simple-text-dialog.component';
import { SimpleTextDialogData } from '../simple-text-dialog/models/simple-text-dialog-data.model';
import { StreappPaymentDialogComponent } from '../streapp-payment-dialog/streapp-payment-dialog.component';
import { StreAppPaymentDialogData } from '../streapp-payment-dialog/streapp-payment-dialog-data.model';
import { MediaItem } from 'src/app/models/mediaitem.model';
import { PaymentRequest } from 'src/app/models/payment-request.model';

@Component({
  selector: 'buy-media',
  templateUrl: './buy-media.component.html',
  styleUrl: './buy-media.component.scss'
})
export class BuyMediaComponent {
  userInfo : UserProfile
  buttonTitle : string

  @Input() StreAppEvent: StreappEvent;
  @Input() SelectedMediaItem: MediaItem;

  constructor(
    private readonly stripeService : StripeService,
    private readonly authService : AuthService,
    private readonly dialog: MatDialog
  ) {
    this.buttonTitle = "Buy media button"
  }

  ngOnInit(): void {
    if (this.isLoggedIn())
    {
      this.authService.getClaimData(true)
      .then((data) => {
        this.userInfo = data;
      })
    }
  }

  createCheckoutSession(): void {
    if (this.isLoggedIn()) {
      this.createPaymentDialog();
    } else {
      this.createLoginDialog();
    }
  }

  createCheckout(): void {
    const PaymentRequest: PaymentRequest = {
      firstName: this.userInfo.firstName,
      amount: this.SelectedMediaItem.price,
      email: this.userInfo.email,
      imageUrl: this.SelectedMediaItem.thumbnail,
      title: this.StreAppEvent.title,
      streappId: this.StreAppEvent.partitionKey,
      mediaItemPartitionKey: this.SelectedMediaItem.partitionKey
    };
  
    this.stripeService.createCheckoutSession(PaymentRequest)
      .subscribe(
        response => {
          const redirectUrl = response.url;
          window.location.href = redirectUrl;
        }
      );
  }

  createLoginDialog(): void {
    this.dialog.open<SimpleTextDialogComponent, SimpleTextDialogData>(
      SimpleTextDialogComponent,
      {
        data: {
          title: 'Login',
          text: 'Om media te kopen moet u ingelogd zijn.',
          showButton: true,
          showCancelButton: true,
          buttonCancelText: 'Cancel',
          buttonText: 'Login',
        }
      }
    )
    .afterClosed()
    .subscribe((data: boolean) => {
      if (data) {
        this.authService.login();
      }
    })    
  }

  createPaymentDialog(): void {
    this.dialog.open<StreappPaymentDialogComponent, StreAppPaymentDialogData>(
      StreappPaymentDialogComponent,
      {
        data: {
          title: 'Kopen',
          text: 'U staat op het punt om dit mediaitem te kopen zonder watermerk.',
          imageUrl: this.SelectedMediaItem.thumbnail,
          showButton: true,
          showCancelButton: true,
          buttonCancelText: 'Annuleer',
          buttonText: 'Kopen',
          price: this.SelectedMediaItem.price,
        }
      }
    )
    .afterClosed()
    .subscribe((data: boolean) => {
      if (data) {
        this.createCheckout();
      }
    })   
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
}
