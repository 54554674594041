import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToFullTimePipe } from './seconds-to-full-time.pipe';
import { DateToDescriptionPipe } from './date-to-description.pipe';
import { DateToDescriptionV2Pipe } from './date-to-desciptionV2.pipe';
import { DateToTimePipe } from './date-to-time.pipe';

@NgModule({
  declarations: [
    SecondsToFullTimePipe,
    DateToDescriptionPipe,
    DateToDescriptionV2Pipe,
    DateToTimePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SecondsToFullTimePipe,
    DateToDescriptionPipe,
    DateToDescriptionV2Pipe,
    DateToTimePipe,
  ]
})
export class PipesModule { }
