import { Component, OnInit, Inject } from '@angular/core';
import { SimpleTextDialogData } from './models/simple-text-dialog-data.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-text-dialog',
  templateUrl: './simple-text-dialog.component.html',
  styleUrls: ['./simple-text-dialog.component.scss']
})
export class SimpleTextDialogComponent implements OnInit {

  constructor(private readonly dialogRef: MatDialogRef<SimpleTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: SimpleTextDialogData) { }

  ngOnInit(): void {
  }

  closeWithConfirm(): void {
    this.dialogRef.close(true);
  }

  close() : void {
    this.dialogRef.close(false);
  }
}
