<div class="dialog" *ngIf="!!data">
  <h2 mat-dialog-title class="header" [ngClass]="data.dialogType">
    <span class="grow">{{data.title | translate}}</span>
    <button class="close-ref" mat-icon-button [title]="'general.close' | translate | titlecase" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div mat-dialog-content>
    <p [innerHTML]="data.text | translate"></p>
  </div>
  <div mat-dialog-actions *ngIf="data.showButton" class="dialog-buttons">
    <button *ngIf="data.showCancelButton" class="close-ref" mat-flat-button color="primary" [title]="data.buttonCancelText | translate" (click)="close()">
      {{data.buttonCancelText | translate}}
    </button>
    <button class="close-ref" [ngClass]="data.dialogType" mat-flat-button color="accent" [title]="data.buttonText | translate" (click)="closeWithConfirm()">
      {{data.buttonText | translate}}
    </button>
  </div>
</div>
