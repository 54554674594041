import { Component, Input } from '@angular/core';

@Component({
  selector: 'qr-code',
  templateUrl: './qr-code.component.html',
  styleUrl: './qr-code.component.scss'
})
export class QrCodeComponent {
  @Input() qrCodeUrl: string;
}
