import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, } from '@angular/core';
import { StreappEvent } from '../../../models/streappevent.model';
import { StreAppDialogComponent } from '../../../components/streapp-dialog/streapp-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { DateToDescriptionPipe } from '../../../pipes/date-to-description.pipe';
import { BaseOverviewLoader } from '../overview-base/overview-base';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-overview-map',
  templateUrl: './overview-map.component.html',
  styleUrls: ['./overview-map.component.scss'],
})
export class OverviewMapComponent extends BaseOverviewLoader implements OnInit, OnDestroy, AfterViewInit { 
  azuremap: any;
  @ViewChild('mapelement') mapelement;
  
  ngOnInit(): void {
    this.locationSet$.subscribe(() => {
      if (isPlatformServer(this._platformId)) {
        return;
      }
      const azurecontrol = require( "azure-maps-control" );
      const options = {
        authType: 'subscriptionKey',
        subscriptionKey: 'SROjwQ2cy75Xj0GHK5hcjAoPMSAGa7s3ozx1C9mm0RQ'
      };
        
      this.azuremap = new azurecontrol.Map(this.mapelement.nativeElement, {
        center: [this.currentLocation.long, this.currentLocation.lat],
        zoom: 13,
        language: 'nl-NL',
        authOptions: options
      });
  
      this.azuremap.controls.add(new azurecontrol.control.ZoomControl(), {
          position: 'top-left'
      });
  
      this.azuremap.controls.add(new azurecontrol.control.CompassControl(), {
        position: 'bottom-left'
      });
  
      this.azuremap.events.add('dragend', (event) => this.dragEnd(event));
      this.azuremap.events.add('ready', (event) => this.loadMarkers());

    });

  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.checkForKnownLocation();
    }
  }

  dragEnd(event) {
      this.mapLocation = {
        long: event.map.getCamera().center[0],
        lat: event.map.getCamera().center[1]
      };

      this.loadMarkers();
  }

  loadMarkers() {
    const azurecontrol = require( "azure-maps-control" );

    this.loadAddsAndEvents().then(() => {
        
      this.azuremap.markers.clear();
      this.allMapMarkers.forEach(item => {
        //Create an HTML marker and add it to the map.
        var marker = new azurecontrol.HtmlMarker({
          position: item.position,
          htmlContent: this.getMarkerContent(item)
        });

        this.azuremap.markers.add(marker);

        //Add a click event to toggle the popup.
        this.azuremap.events.add('click',marker, () => {
          this.navigateToEvent(item.event);
        });
      });

      this.locationAds.forEach(item => {
        //Create an HTML marker and add it to the map.
        var marker = new azurecontrol.HtmlMarker({
          position: item.position,
          htmlContent: this.getMarkerContentAds(item)
        });

        this.azuremap.markers.add(marker);

        //Add a click event to toggle the popup.
        this.azuremap.events.add('click',marker, () => {
          this.adClicked(item.event);
        });
      });

      this.locationPromos.forEach(item => {
        //Create an HTML marker and add it to the map.
        var marker = new azurecontrol.HtmlMarker({
          position: item.position,
          htmlContent: this.getMarkerContentAds(item)
        });

        this.azuremap.markers.add(marker);

        //Add a click event to toggle the popup.
        this.azuremap.events.add('click',marker, () => {
          this.adClicked(item.event);
        });
      });
    }); 
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigateToEvent(event: StreappEvent): void {
    this.dialog.open<StreAppDialogComponent, StreappEvent>(StreAppDialogComponent, {
      data: event
    }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
      if (data) {
        this.router.navigate(['/detail', event.partitionKey]);
      }
    });
  }

  getMarkerContent(marker: any) {
    const dateToDescription: DateToDescriptionPipe = new DateToDescriptionPipe();
    if (marker.event.isReporter) {
      return '<div class="marker"><div class="pin reporter bounce"><div class="marker-title"><div class="filler"></div><div class="title">' + marker.label.text + '<div><span class="date">' + dateToDescription.transform(marker.event.created) + '</span></div></div></div>';
    } else {
      return '<div class="marker"><div class="pin bounce"><div class="marker-title"><div class="filler"></div><div class="title">' + marker.label.text + '<div><span class="date">' + dateToDescription.transform(marker.event.created) + '</span></div></div></div>';
    }
  }

  getMarkerContentAds(marker: any) {
    let content;
    if (!marker.event.pageTitle) {
      this.translate.get('ads.name').pipe(takeUntil(this.destroy$)).subscribe(text => {
        content = '<div class="marker"><div class="pin ad bounce"><div class="marker-title"><div class="filler"></div><div class="title">' + marker.label.text + '<div><span class="date">' + text + '</span></div></div></div>';
      });
    } else {
      // bug: this causes the pin to bounce again after 1 minute has passed.
      let text = this.getCountDown(marker.event);
      content = '<div class="marker"><div class="pin ad bounce"><div class="marker-title"><div class="filler"></div><div class="title">' + marker.label.text + '<div><span class="date">' + text + '</span></div></div></div>';
    }
    return content;
  }

  resetMapToMyLocation(): void {
    this.azuremap.setCamera({ center: [this.currentLocation.long, this.currentLocation.lat], type: 'fly' });

    // Update map location and retrieve new events for this location
    this.mapLocation = {
      long: this.currentLocation.long,
      lat: this.currentLocation.lat
    };
    this.loadMarkers();
  }

  resetMapToNewLocation(location: string): void {
    if (!!location) {
      this.azuremap.setCamera({ center: [+location.split(';')[0], +location.split(';')[1]], type: 'fly' });
      // Update map location and retrieve new events for this location
      this.mapLocation = {
        long: +location.split(';')[0],
        lat: +location.split(';')[1]
      };
      this.loadMarkers();
    }
  }

}
