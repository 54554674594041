<div class="dialog">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'share.title' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
        <div class="wrapper">
            <button mat-flat-button *ngIf="instagram" (click)="share('instagram')" class="reference">
                <img 
                    src="/assets/images/socials/Instagram_color.png"
                    alt="instagram-logo"
                />
                {{ 'share.instagram' | translate }}
            </button>
            <button mat-flat-button *ngIf="facebook" (click)="share('facebook')" class="reference">
                <img 
                    src="/assets/images/socials/Facebook_color.png"
                    alt="facebook-logo"
                />
                {{ 'share.facebook' | translate }}
            </button>
            <button mat-flat-button *ngIf="x" (click)="share('x')" class="reference">
                <img 
                    src="/assets/images/socials/X_logo_2023_original.svg"
                    alt="x-logo"
                />
                {{ 'share.x' | translate }}
            </button>
            <button mat-flat-button class="reference" (click)="copy()">
                <mat-icon>link</mat-icon> {{ (copied ? 'share.copied' : 'share.copy') | translate }}
            </button>
            <qr-code [qrCodeUrl]="url"></qr-code>
        </div>
    </div>
    <div mat-dialog-actions class="dialog-button-end">
      <button class="close-ref cancel" mat-flat-button color="primary" [title]="'general.close' | translate | titlecase" (click)="close()">
        {{ 'general.close' | translate}}
      </button>
    </div>
</div>