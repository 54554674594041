import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'streapp-payment-cancel',
  templateUrl: './streapp-payment-cancel.component.html',
  styleUrl: './streapp-payment-cancel.component.scss'
})
export class StreappPaymentCancelComponent implements OnInit {
  id: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
    });
  }

  retryCheckout(): void {
    if(!this.id){
      throw new Error('No id found');
    }
    this.router.navigate(['/detail', this.id]);
  }
}
