import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from 'src/app/components/contact-dialog/contact-dialog.component';
import { FeedbackDialogComponent } from 'src/app/components/feedback-dialog/feedback-dialog.component';

@Component({
  selector: 'info',
  templateUrl: './info.component.html',
  styleUrl: './info.component.scss',
})
export class InfoComponent {
  constructor(private readonly dialog: MatDialog) {}

  openFeedbackDialog(): void {
    this.dialog
      .open<FeedbackDialogComponent>(FeedbackDialogComponent)
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
        }
      });
  }

  openContactDialog(): void {
    this.dialog.open<ContactDialogComponent>(ContactDialogComponent, {
      autoFocus: false, // To prevent select state of close button in header
    });
  }

  goToAboutUsWebsite(): void {
    window.open('https://www.streapp.nl/');
  }

  downloadTerms(): void {
    window.open('/StreApp-Algemene-voorwaarden.pdf');
  }
}
