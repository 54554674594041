import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StreApperInfo } from 'src/app/models/streapper-info.model';

@Component({
  selector: 'reporter-card',
  templateUrl: './reporter-card.component.html',
  styleUrls: ['./reporter-card.component.scss'],
})
export class ReporterCardComponent {
  @Input() streapper: StreApperInfo;
  fav: boolean;

  constructor(
    private readonly router: Router,
    private domSanitizer: DomSanitizer
  ) {}

  getProfilePicture() {
    if (this.streapper.picture) {
      if (this.streapper.picture?.startsWith('http')) {
        return this.streapper.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.streapper.picture
      );
    }
  }

  navigateToProfile() {
    this.router.navigate(['/reporters/detail'], {
      queryParams: { id: this.streapper.id },
      replaceUrl: true,
    });
  }
}
