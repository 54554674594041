import { Component, Input } from '@angular/core';
import { ShareDialogComponent } from '../share-dialog/share-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'share-button',
  templateUrl: './share-button.component.html',
  styleUrl: './share-button.component.scss'
})
export class ShareButtonComponent  {
  @Input() shareTitle: string = '';

  constructor(
    private readonly dialog: MatDialog,
  ) {}

  share(): void {
        this.dialog.open<ShareDialogComponent>(
          ShareDialogComponent,
          {
            autoFocus: false, // To prevent select state of close button in header
            data: {
              url: window.location.href,
              title: this.shareTitle,
              facebook: true,
              x: true
            }
          }
        );
  }
}
