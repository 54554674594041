import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-price-dialog',
  templateUrl: './price-dialog.component.html',
  styleUrls: ['./price-dialog.component.scss']
})
export class PriceDialogComponent implements OnInit {
  public startPrice : any = this.data.price;

  constructor(
    private readonly dialogRef: MatDialogRef<PriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { price: any, priceSuggestion: string },

  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(newPrice : any): void {
    // Check if there's a valid input, including free as an option (0.00)
    if(newPrice || newPrice == "0.00"){
      // Set 2 decimals behind the text
      var n = newPrice.toFixed(2);
      this.dialogRef.close({n});
    } else {
      // When an input is invalid return old number
      var n = this.data.price;
      this.dialogRef.close({n});
    }
  }
}
