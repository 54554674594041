import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentRequest } from '../models/payment-request.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private baseApiUrl : string;

  constructor(private http: HttpClient,
    private configService: AppConfigService) {
    this.baseApiUrl = this.configService.getConfig().baseApiUrl;
  }

  createCheckoutSession(PaymentRequest: PaymentRequest): Observable<{ url: string }> {
    return this.http.post<{ url: string }>(this.baseApiUrl + 'Payment/Checkout', PaymentRequest);
  }
}
