import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";

@Pipe({
  name: 'dateToTime'
})
export class DateToTimePipe implements PipeTransform {
    transform(date: Date): string {
        return formatDate(date, 'HH:mm \'uur\'', 'nl-NL');
    }
}