import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-streapp-payment-success',
  templateUrl: './streapp-payment-success.component.html',
  styleUrl: './streapp-payment-success.component.scss'
})
export class StreappPaymentSuccessComponent implements OnInit {
  email: string;

  constructor(
    private route: ActivatedRoute, 
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
    });
  }

  redirectHome(): void {
    window.location.href = '/';
  }
}
