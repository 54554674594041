<div class="dialog">
  <h2 mat-dialog-title class="header">
    {{ 'new_event.similar_events_dialog.title' | translate }}
    <button class="close-ref" mat-icon-button (click)="closeAndCancel()" [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div class="profile-content" mat-dialog-content *ngIf="!!data && data.length > 0">
    <div class="similar-content" [innerHTML]="'new_event.similar_events_dialog.explain_text' | translate"></div>
    <div class="overview-scroll-content">
      <overview-card *ngFor="let item of data" [event]="item" [navigateToEventOnClick]="false" (click)="closeWithLinking(item)" [title]="'Koppel aan dit event'"></overview-card>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button mat-stroked-button (click)="closeAndCancel()" [title]="'general.cancel' | translate | titlecase">
      {{ 'general.cancel' | translate }}
    </button>
    <button class="close-ref" mat-flat-button (click)="closeWithoutLinking()" [color]="'primary'" [title]="'new_event.similar_events_dialog.new_event' | translate">
      {{ 'new_event.similar_events_dialog.new_event' | translate }}
    </button>
  </div>
</div>
