import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sebbies-dialog',
  templateUrl: './sebbies-dialog.component.html',
  styleUrl: './sebbies-dialog.component.scss'
})
export class SebbiesDialogComponent {
    constructor(
      private readonly dialogRef: MatDialogRef<SebbiesDialogComponent>
    ) {}

    closeWithConfirm(): void {
      this.dialogRef.close(true);
    }
  
    close() : void {
      this.dialogRef.close(false);
    }
}
