<div class="popup-container">
    <div class="popup-content">
        <img src="/assets/images/Coins-3.png" alt="Coins" class="popup-image" />
        <h2 class="popup-title">Gefeliciteerd!</h2>
        <p class="popup-text">+25 Sebbies</p>
    </div>
    <button class="popup-button" (click)="closeWithConfirm()">Sluiten</button>
</div>

  
