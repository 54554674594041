export interface MediaItem {
  partitionKey: string;
  rowKey: string;
  timestamp: Date;
  fileName: string;
  thumbnail: string;
  longtitude: number;
  latitude: number;
  created: Date;
  createdBy: string;
  isReporter: boolean;
  url: string;
  videoDurationInSeconds: number;
  mediaType: MediaType;
  price: number;
}

export enum MediaType {
    Unknown = 0,
    Video = 1,
    Image = 2 
}