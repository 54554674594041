<div
  class="desktop-width-limiter detail-page page-padding"
  [class.d-none]="isServer"
>
  <ng-container *ngIf="!loading && !error && event">
      <div class="detail-page-content">
      <div class="page-content">
        <div class="video-player-container">
          <div class="video-player">
            <div class="media-and-buttons">
              <button *ngIf="event.mediaItems.length > 1 && selectedItemIndex != 0" class="arrow left" (click)="navigate(-1)">&#8249;</button>
              <video-player
                *ngIf="
                  selectedMediaItem &&
                  selectedMediaItem.mediaType == MediaType.Video &&
                  !selectedMediaItem.url
                "
                [id]="'vid1'"
                [src]=""
                [autoplay]="false"
                [width]="'100%'"
                [height]="isMobile ? '250px' : '400px'"
              >
                {{ selectedMediaItem | json }}
              </video-player>
              <!-- Below element is inserted twice so make sure change detection properly triggers when switching from video -->
              <video-player
                *ngIf="
                  selectedMediaItem &&
                  selectedMediaItem.mediaType == MediaType.Video &&
                  selectedMediaItem.url &&
                  selectedItem === 0
                "
                [id]="'vid1'"
                [videoId]="selectedMediaItem.partitionKey.replace('.', '_')"
                [src]="selectedMediaItem.url"
                [autoplay]="false"
                [width]="'100%'"
                [height]="isMobile ? '250px' : '400px'"
                [poster]="getThumbnailUrl(selectedMediaItem.thumbnail)"
                [isHighImpactEvent]="event.isHighImpactEvent"
                [rawInput]="selectedMediaItem.url.indexOf('manifest') === -1"
              >
                {{ selectedMediaItem | json }}
              </video-player>
              <video-player
                *ngIf="
                  selectedMediaItem &&
                  selectedMediaItem.mediaType == MediaType.Video &&
                  selectedMediaItem.url &&
                  selectedItem === 1
                "
                [id]="'vid1'"
                [videoId]="selectedMediaItem.partitionKey.replace('.', '_')"
                [src]="selectedMediaItem.url"
                [autoplay]="false"
                [width]="'100%'"
                [height]="isMobile ? '250px' : '400px'"
                [poster]="getThumbnailUrl(selectedMediaItem.thumbnail)"
                [isHighImpactEvent]="event.isHighImpactEvent"
                [rawInput]="selectedMediaItem.url.indexOf('manifest') === -1"
              >
                {{ selectedMediaItem | json }}
              </video-player>
              <!-- Video still processing error text -->
              <div class="processing-container" *ngIf="!selectedMediaItem.url">
                <span class="processing-text">{{
                  "page.detail.processing" | translate
                }}</span>
              </div>
              <img
                class="media-image"
                *ngIf="
                  selectedMediaItem &&
                  selectedMediaItem.mediaType == MediaType.Image
                "
                [src]="selectedMediaItem.url"
                onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';"
              />
              <button *ngIf="event.mediaItems.length > 1 && selectedItemIndex != (event.mediaItems.length-1)" class="arrow right" (click)="navigate(1)">&#8250;</button>
            </div>
          </div>
          <div class="header">
            <h1 class="pageTitle" [title]="event.title">
                {{ event.title }}
            </h1>
          </div>
          <div class="profile-and-buttons">
            <page-attributes [event]="event"></page-attributes>
            <div class="buttons">
              <!-- Share button -->
              <share-button [shareTitle]="event.title"></share-button> 
              <!-- Download button -->
              <download-button
                [downloadLink]="this.selectedMediaItem.url"
                *ngIf="isMyStreApp && selectedMediaItem.mediaType == MediaType.Image"
                [buttonTitle]="'download.download_selectedMediaItem'"></download-button>
              <!-- Add content button -->
              <add-content-button
                  *ngIf="isMyStreApp"
                  [eventId]="event.partitionKey">
              </add-content-button>
              <!-- Edit content button -->
              <edit-content-button
                  *ngIf="isMyStreApp"
                  [eventId]="event.partitionKey">
              </edit-content-button>
              <!-- Delete StreApp Button -->
              <delete-streapp-button
                  *ngIf="isMyStreApp"
                  [eventId]="event.partitionKey"
                  [eventTitle]="event.title"
              ></delete-streapp-button>
              <!-- Report button -->
              <reporting-button
                  [eventId]="event.partitionKey"
                  *ngIf="isLoggedIn() && !isMyStreApp"
                  [buttonTitle]="'report.report_event'">
              </reporting-button>
              <!-- Buy media button -->
              <!-- <buy-media 
                  [StreAppEvent]="event" 
                  [SelectedMediaItem]="selectedMediaItem">
              </buy-media> -->
            </div>
          </div>
          <div class="description" [title]="'general.description' | translate">
            <p #description class="container" [class.show]="show">
              {{ event.description }}
            </p>
            <button matRipple *ngIf="checkOverflow(description)" (click)="show = !show">{{ "page.detail.buttons.read_more" | translate }}</button> 
            <button matRipple *ngIf="show" (click)="show = !show">{{ "page.detail.buttons.read_less" | translate }}</button>
          </div>
          <image-carousel
          [mediaItemArray]="event.mediaItems"
          [selectedMediaItem]="selectedMediaItem"
          (mediaItemClicked)="selectMediaItem($event)"
          *ngIf="event.mediaItems.length > 1">
        </image-carousel>
        </div>
        <div class="linked-events" *ngIf="event.linkedStreappEvents?.length > 0 || (event.linkedStreappEvents?.length === 0 && showLinkedEvents)">
          <!-- Hier komen gekoppelde streapps, of relevante streapps -->
          <linked-events
            *ngIf="event.linkedStreappEvents?.length > 0"
            [event]="event"
          ></linked-events>
          <related-events
            *ngIf="event.linkedStreappEvents?.length === 0"
            [event]="event"
            (getShowRelatedEvents)="updateShowLinkedEvents($event)"
          ></related-events>
        </div>
      </div>
      <div class="side-container">
        <reaction
          [items]="reactions"
          (addReaction)="addReaction($event)"
          (removeReaction)="removeReaction($event)"
          (updateReaction)="updateReaction($event)"
          [eventId]="event.partitionKey">
        </reaction>
        <div class="ads-vertical">
          <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-1807328328271021"
            data-ad-slot="7063841727"
            data-ad-format="vertical"
            data-full-width-responsive="true">
          </ins>
        </div>
      </div>
    </div>
    <scroll-up *ngIf="hasScrolled"></scroll-up>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="75"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && error">
    <div class="errorText">
      {{ "page.error" | translate }}
    </div>
  </ng-container>
</div>
