<div class="reactions">
  <div class="reaction-text">
    <div [title]="'page.reageren.total_reactions' | translate"> {{ items?.length || 0 }} Reacties </div>
    <div> {{'page.reageren.guidelines_text'| translate}} </div>
  </div>
  <mat-list>
    <mat-list-item id="reactions" class="row add-reaction" *ngIf="isLoggedIn()">
      <div class="mat-list-form mat-list-textblock">
        <form [formGroup]="reactionForm" (ngSubmit)="formSubmit(); reactionForm.reset()" (keydown.enter)="addEnter($event)">
          <mat-form-field appearance="outline" class="col-12 no-padding">
            <textarea #input maxlength="1000" autosize rows="2" minRows="1" maxRows="10" formControlName="text" matInput [placeholder]="'page.reageren.placeholder' | translate"></textarea>
            <mat-hint align="end">{{input.value?.length || 0}} / 1000</mat-hint>
          </mat-form-field>
          <button class="d-sm-none" mat-icon-button [disabled]="!reactionForm.valid || !reactionForm.dirty" type="submit" color="primary">
            <mat-icon [title]="'reactions.r1' | translate">sms</mat-icon>
          </button>
          <button class="d-sm-none clear" mat-icon-button (click)="reactionForm.reset();" *ngIf="reactionForm.dirty">
            <mat-icon [title]="'reactions.r4' | translate">clear</mat-icon>
          </button>
          <button class="d-none d-sm-block" [title]="'page.reageren.button_reageren' | translate"
                  mat-flat-button [disabled]="!reactionForm.valid || !reactionForm.dirty" type="submit" color="primary">
            {{'page.reageren.button_reageren' | translate | uppercase}}
          </button>
          <button class="d-none d-sm-block" [title]="'page.reageren.button_annuleren' | translate"
                  mat-stroked-button *ngIf="reactionForm.valid && reactionForm.dirty" (click)="reactionForm.reset();">
            {{'page.reageren.button_annuleren' | translate | uppercase}}
          </button>
        </form>
      </div>
    </mat-list-item>

    <div class="datetime" *ngIf="!isLoggedIn()">{{'page.reageren.not_logged_in' | translate}}</div>

    <ng-container *ngFor="let item of items" >
      <mat-list-item class="row">
        <div class="mat-list-textblock">
          <div class="mat-header">
            <img class="profile-image" [src]="profilePictures.get(item.createdBy)" alt="Profile Picture" onerror="this.onerror=null;this.src='/assets/images/user.png';" height="40" width="40" >
            <div class="mat-title-container">
              <div class="mat-list-title">
                <span> Door </span>
                {{item.createdByName}}
              </div>
              <span class="datetime mat-list-date">
              {{item.created | dateToDescriptionV2}}
              </span>
            </div>
          </div>
          <span class="mat-list-text"
                [innerHTML]="sanitizedText(item.content)" *ngIf="!item.deleted"></span>
          <span class="mat-list-text mat-list-text--italic" *ngIf="item.deleted">
            {{'page.reageren.reactie_deleted' | translate}}
          </span>
          <span class="datetime modified" *ngIf="item.created !== item.modified">
             {{'page.reageren.modified' | translate}} {{item.modified | dateToDescriptionV2}} {{'page.reageren.modified_comment' | translate}}
          </span>
        </div>
        <div class="mat-list-iconblock">
          <ng-container *ngIf="user === item.createdBy && !item.deleted && isLoggedIn()">
            <button (click)="editItem(item.partitionKey)" mat-icon-button color="primary" [title]="'page.reageren.modify' | translate">
              <mat-icon>edit</mat-icon>
            </button>
            <button (click)="deleteReaction(item.partitionKey)" mat-icon-button color="primary" [title]="'page.reageren.delete' | translate">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="user !== item.createdBy && !item.deleted && isLoggedIn()">
            <reporting-button [userId]="item.partitionKey" [eventId]="eventId" *ngIf="isLoggedIn()" [buttonTitle]="'report.report_reaction'" [showText]="false"></reporting-button>
          </ng-container>
        </div>
      </mat-list-item>
      <mat-list-item class="row add-reaction" *ngIf="isLoggedIn() && editableItem === item.partitionKey">
        <div class="mat-list-form mat-list-textblock">
          <form [formGroup]="reactionReplyForm" (ngSubmit)="editReaction(); reactionReplyForm.reset()" (keydown.enter)="addEnter($event)">
            <mat-form-field appearance="outline" class="col-12 no-padding">
              <textarea #input maxlength="1000" autosize rows="2" minRows="1" maxRows="10" formControlName="text" matInput [placeholder]="'page.reageren.placeholder' | translate"></textarea>
              <mat-hint align="end">{{input.value?.length || 0}} / 1000</mat-hint>
            </mat-form-field>
            <button class="d-sm-none" mat-icon-button [disabled]="!reactionReplyForm.valid || !reactionReplyForm.dirty" type="submit" color="primary">
              <mat-icon [title]="'reactions.r1' | translate">sms</mat-icon>
            </button>
            <button class="d-sm-none clear" mat-icon-button (click)="reactionReplyForm.reset(); cancelUpdate()">
              <mat-icon [title]="'reactions.r4' | translate">clear</mat-icon>
            </button>
            <button class="d-none d-sm-block" [title]="'page.reageren.button_updaten' | translate"
                    mat-flat-button [disabled]="!reactionReplyForm.valid || !reactionReplyForm.dirty" type="submit" color="primary">
              {{'page.reageren.button_updaten' | translate | uppercase}}
            </button>
            <button class="d-none d-sm-block" [title]="'page.reageren.button_annuleren' | translate"
                    mat-stroked-button (click)="reactionReplyForm.reset(); cancelUpdate()">
              {{'page.reageren.button_annuleren' | translate | uppercase}}
            </button>
          </form>
        </div>
      </mat-list-item>
    </ng-container>
  </mat-list>
</div>
