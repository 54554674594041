import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrl: './delete-account-dialog.component.scss',
})
export class DeleteAccountDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<DeleteAccountDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  delete(): void {
    console.log('Account Deleted');
  }
}
