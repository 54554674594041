<ng-container *ngIf="!!event">
  <div class="page-attributes">
    <div class="attribute cursor-pointer image-section" *ngIf="event.createdByName" [title]="'page.detail.navigate_to_profile' | translate">
      <a [routerLink]="generateLink()" [queryParams]="{ id: event.createdBy }">
        <img class="profile-image" alt="Profielafbeelding" [src]="getEventProfilePicture()"
        onerror="this.onerror=null;this.src='/assets/images/user.png';" height="50" width="50" />
      </a>
      <div class="name-and-reporter">
        <a [routerLink]="generateLink()" [queryParams]="{ id: event.createdBy }">
          <span class="username">{{event.createdByName}}</span>
        </a>
        <img class="reporter-image" alt="StreApp reporter" *ngIf="event.isReporter" src="/assets/images/tekst_streapp_reporter_grey_01.svg" />
      </div>
    </div>
    <div class="attributes">
      <div class="attribute" *ngIf="event.locationCity" [title]="event.locationCity">
        <span class="location">{{event.locationCity}}</span>
      </div>
      <span>•</span>
      <div class="attribute" *ngIf="event.category" [title]="event.created | dateToDescriptionV2">
        <span class="date">{{ event.created | dateToDescriptionV2}}</span>
      </div>
    </div>
    <div class="attribute" *ngIf="event.tags" [title]="event.tags">
      <span class="label">{{'page.detail.tags' | translate}}</span>
      <span class="tags">{{event.tags}}</span>
    </div>
  </div>
</ng-container>