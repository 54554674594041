import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  location: { long: number, lat: number, radius: number };

  private searchService: any;

  constructor(private readonly httpClient: HttpClient) {
    this.initializeSearchService();
  }

  private async initializeSearchService(): Promise<void> {
    const azure = await import('ng-azure-maps');
    this.searchService = new azure.SearchService(this.httpClient);
  }

  public async searchAddressReverse(long: number, lat: number): Promise<any> {
    try {
      let results = await this.searchService
        .searchAddressReverse([long, lat], {})
        .toPromise();

      if (results.summary?.numResults > 0) {
          const result = results.addresses[0];
          return result.address;
        }
    } catch (error) {
      throw error;
    }
  }
}
